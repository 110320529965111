const { Connection, PublicKey } = require("@solana/web3.js");
import { oraclePublicKey } from "@/assets/js/oracleList";
import { getTokenPriceOnly } from "@/assets/js/oracle";
import { SY_LEN, getSapData, getSyData } from "./sap/sap";
import { rpcUrl } from "./index";
import { getTokenInfo } from "./token";

export async function findSYTokenPrices3(url, mints, totalValue, sapData) {
  let product_prices = [];
  let connection = new Connection(url);
  let BasePrice = sapData.initPrice;
  let startTime = sapData.initTime * 1000;
  let nowTime = new Date().getTime();
  let D = Math.round((nowTime - startTime) / (24 * 3600 * 1000));
  // find supply for each product
  let supplies = [];

  for (let j = 0; j < SY_LEN; j++) {
    let supply = await connection.getTokenSupply(new PublicKey(mints[j]));
    supplies.push(supply.value.uiAmount);
  }
  let r1S = supplies[0];
  let r2S = supplies[1];
  let totalS = r1S + r2S;
  let preSupply1 =
    sapData.preMintL1Amount / 10 ** 9 / sapData.preMintL1ClaimRate;
  let preSupply2 =
    sapData.preMintL2Amount / 10 ** 9 / sapData.preMintL2ClaimRate;
  totalS += preSupply1 + preSupply2;
  let newPrice = totalValue / totalS;
  let APY = 0.2;
  // let D = 35
  let profit = (newPrice - BasePrice) / BasePrice;
  let reward1 = 0;
  let reward2 = 0;
  reward1 = (APY / 365) * D;
  reward2 = ((r1S + r2S) * profit - (APY / 365) * D * r1S) / r2S;
  if (sapData.status === 2 || sapData.status == 3) {
    product_prices = [reward1, reward2];
  } else {
    product_prices = [BasePrice, BasePrice];
  }
  let values;
  if (r1S + r2S > 0) {
    values = [(totalValue * r1S) / totalS, (totalValue * r2S) / totalS];
  } else {
    values = [0, 0];
  }
  return [supplies, product_prices, values];
}

export async function findSYTokenSupplies(url, mints) {
  let connection = new Connection(url);
  // find supply for each product
  let supplies = [];
  for (let j = 0; j < mints.length; j++) {
    let res = await connection.getTokenSupply(new PublicKey(mints[j]));
    let supply = parseFloat(res.value.uiAmount);
    supplies.push(supply);
  }
  return supplies;
}

export async function getAssetValue(connection, product) {
  let totalValue = 0;
  let tokens = product.tokenList;
  let maxV = 0;
  let maxA = "";
  let assets = [];
  if (!product.enableWoo) {
    for (let i = 0; i < tokens.length; i++) {
      let res = await getTokenAccountBalance3(connection, product.vaultList[i]);
      let thisTokenPrice = await getTokenPriceLocal(rpcUrl, tokens[i].name);
      tokens[i].price = thisTokenPrice;
      let amount = res.value.uiAmount;
      let thisV = amount * tokens[i].price;
      totalValue += thisV;
      if (thisV > 0) {
        assets.push({ name: tokens[i].name, value: thisV });
      }
      if (thisV > maxV) {
        maxV = thisV;
        maxA = tokens[i].name;
      }
    }
  } else {
    let { assetList } = product.wooAsset;
    console.log("get woo asset", assetList);
    for (let i = 0; i < assetList.length; i++) {
      let asset = assetList[i];
      let { name, amount, price, value } = asset;
      totalValue += value;
      assets.push({ name, value });
      if (value > maxV) {
        maxV = value;
        maxA = name;
      }
    }
  }
  const mint = product.mint[0];
  let res = await connection.getTokenSupply(new PublicKey(mint));
  let supply = parseFloat(res.value.uiAmount);
  let basePrice = product.init_price;
  let fee = 0;
  let res1 = await getSapData(connection, product.pubkey);
  if (res1.code == 1) {
    let sapData = res1.data;
    fee = sapData.fee + sapData.performanceFee;
  } else {
    console.error("get sap data fali", res1);
  }
  let price = (totalValue - fee) / supply;
  console.log(
    product.name,
    "price",
    price,
    "value",
    totalValue,
    "fee",
    fee,
    "supply",
    supply
  );
  let period = Math.max(
    1,
    Math.floor(
      (new Date().getTime() - new Date(product.initialized_date).getTime()) /
        3600000 /
        365 /
        24
    )
  );
  let apy = (1 + (price - basePrice) / basePrice / period) ** (1 / period) - 1;
  assets = assets.sort(function(a, b) {
    return b.value - a.value;
  });
  let percent = (price - basePrice) / basePrice;
  let cap = totalValue - fee;
  return [maxA, percent, apy, cap, supply, price, assets];
}

export async function getAssetValueSY(connection, rpcUrl, product) {
  let totalValue = 0;
  let tokens = product.tokenList;
  let maxV = 0;
  let maxA = "";
  let assets = [];
  if (!product.enableWoo) {
    for (let i = 0; i < tokens.length; i++) {
      // get token price and value
      let res = await connection.getTokenAccountBalance(
        new PublicKey(product.vaultList[i])
      );
      let thisTokenPrice = await getTokenPriceLocal(rpcUrl, tokens[i].name);
      tokens[i].price = thisTokenPrice;
      let amount = res.value.uiAmount;
      let thisV = amount * tokens[i].price;
      // console.log('token name', tokens[i].name, 'token price', tokens[i].price, 'token amount', res.value.uiAmount, 'token value', thisV)
      totalValue += thisV;
      if (thisV > maxV) {
        maxV = thisV;
        // maxA = tokens[i].pair.replace('/USD','')
        maxA = tokens[i].name;
      }
      if (thisV > 0) {
        assets.push({ name: tokens[i].name, value: thisV });
      }
    }
  } else {
    let { assetList } = product.wooAsset;
    for (let i = 0; i < assetList.length; i++) {
      let asset = assetList[i];
      let { name, amount, price, value } = asset;
      totalValue += value;
      assets.push({ name, value });
      if (value > maxV) {
        maxV = value;
        maxA = name;
      }
    }
  }
  // calculate sap price
  let sapData = { status: 0, fee: 0, performanceFee: 0 };
  {
    let res = await getSyData(connection, product.pubkey);
    if (res.code == 1) {
      sapData = res.data;
    }
  }
  let mints = product.mint;
  mints = mints.slice(0, SY_LEN);
  let [r1s, r2s] = await findSYTokenSupplies(rpcUrl, mints);
  let totalSupply = r1s + r2s;
  let totalSupply1 = r1s + r2s;
  console.log("origin supply", totalSupply);
  r1s += sapData.preMintL1Amount / 10 ** 9;
  r2s += sapData.preMintL2Amount / 10 ** 9;
  let fee = sapData.fee + sapData.performanceFee;
  const basePrice = sapData.initPrice || 100.0;
  totalValue -= fee;
  let preSupply1 =
    sapData.preMintL1Amount / 10 ** 9 / sapData.preMintL1ClaimRate;
  let preSupply2 =
    sapData.preMintL2Amount / 10 ** 9 / sapData.preMintL2ClaimRate;
  totalSupply += preSupply1 + preSupply2;
  console.log("supply with pre mint", totalSupply);
  const sapPrice = totalSupply > 0 ? totalValue / totalSupply : basePrice;
  console.log(
    product.name,
    "price",
    sapPrice,
    "value",
    totalValue,
    "fee",
    fee,
    "supply",
    totalSupply
  );
  // calculate idx
  const startDate = new Date(product.initialized_date);
  const nowDate = new Date();
  console.log("date", startDate, nowDate);
  const period = Math.max(
    1,
    Math.floor(
      (nowDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24 * 365)
    )
  );
  const idx = (sapPrice - basePrice) / basePrice;
  const idx1y =
    (1 + (sapPrice - basePrice) / basePrice / period) ** (1 / period) - 1;
  const idx30d = Math.pow(idx1y / (365 / 30) + 1, 365 / 30) - 1;
  const idx1d = Math.pow(idx1y / 365 + 1, 365) - 1;
  // calculate sy apy
  const apy = 0.2;
  const d = Math.round(
    (nowDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
  );
  // calculate r1
  let apy1 = (apy / 365) * d;
  let apy1y1 = (apy / 365) * 365;
  let apy30d1 = (apy / 365) * 30;
  let apy1d1 = (apy / 365) * 1;
  // calculate r2
  let apy2 = (totalSupply1 * idx - apy1 * r1s) / r2s;
  let apy1y2 = (totalSupply1 * idx1y - apy1y1 * r1s) / r2s;
  let apy30d2 = (totalSupply1 * idx30d - apy30d1 * r1s) / r2s;
  let apy1d2 = (totalSupply1 * idx1d - apy1d1 * r1s) / r2s;
  if (r2s == 0) {
    apy2 = 0.0;
    apy1y2 = 0.0;
    apy30d2 = 0.0;
    apy1d2 = 0.0;
  }
  if (apy2 < -1) {
    apy2 = -1;
    apy1y2 = -1;
    apy30d2 = -1;
    apy1d2 = -1;
  }
  let price = [100 * (1 + apy1), 100 * (1 + apy2)];
  console.log(product.name, "r1 r2 price", price);
  let cap = totalValue;
  let cap1 = (cap * r1s) / totalSupply;
  let cap2 = (cap * r2s) / totalSupply;
  assets = assets.sort(function(a, b) {
    return b.value - a.value;
  });
  let assetValue1 = [
    maxA,
    apy1,
    apy1y1,
    apy30d1,
    apy1d1,
    cap1,
    r1s,
    price[0],
    assets,
  ];
  let assetValue2 = [
    maxA,
    apy2,
    apy1y2,
    apy30d2,
    apy1d2,
    cap2,
    r2s,
    price[1],
    assets,
  ];
  return [assetValue1, assetValue2];
}

export async function getAssetValueSY2(
  connection,
  rpcUrl,
  product,
  sapProduct
) {
  // get sap price
  var totalValue = 0;
  const tokens = sapProduct.tokenList;
  const vaults = sapProduct.vaultList;
  var maxV = 0;
  var maxA = "";
  var assets = [];
  if (!product.enableWoo) {
    for (let i = 0; i < tokens.length; i++) {
      let res = await connection.getTokenAccountBalance(
        new PublicKey(vaults[i])
      );
      let thisTokenPrice = await getTokenPriceLocal(rpcUrl, tokens[i].name);
      tokens[i].price = thisTokenPrice;
      let thisV = res.value.uiAmount * tokens[i].price;
      totalValue += thisV;
      if (thisV > 0) {
        assets.push({ name: tokens[i].name, value: thisV });
      }
      if (thisV > maxV) {
        maxV = thisV;
        maxA = tokens[i].name;
      }
    }
  } else {
    console.log(product);
    let { assetList } = product.wooAsset;
    console.log("get woo asset", assetList);
    for (let i = 0; i < assetList.length; i++) {
      let asset = assetList[i];
      let { name, amount, price, value } = asset;
      totalValue += value;
      assets.push({ name, value });
      if (value > maxV) {
        maxV = value;
        maxA = name;
      }
    }
  }
  const sapMint = sapProduct.mint[0];
  // console.log('sap mint', sapMint);
  let sapRes = await connection.getTokenSupply(new PublicKey(sapMint));
  let sapSupply = parseFloat(sapRes.value.uiAmount);
  let basePrice = 100;
  let sapPrice = totalValue / sapSupply;
  console.log(product.name, "price", sapPrice);
  // calculate sap idx
  let period = Math.max(
    1,
    Math.floor(
      (new Date().getTime() - new Date(product.initialized_date).getTime()) /
        3600000 /
        365 /
        24
    )
  );
  const idx = (sapPrice - basePrice) / basePrice;
  const idx1y =
    (1 + (sapPrice - basePrice) / basePrice / period) ** (1 / period) - 1;
  const idx30d = Math.pow(idx1y / (365 / 30) + 1, 365 / 30) - 1;
  const idx1d = Math.pow(idx1y / 365 + 1, 365) - 1;
  console.log("sap idx", idx, idx1y, idx30d, idx1d);
  // calculate sy apy
  const apy = 0.2;
  const d = Math.max(
    1,
    Math.floor(
      (new Date().getTime() - new Date(product.initialized_date).getTime()) /
        3600000 /
        24
    )
  );
  assets = assets.sort(function(a, b) {
    return b.value - a.value;
  });
  const mints = product.mint.slice(0, SY_LEN);
  const [r1s, r2s] = await findSYTokenSupplies(rpcUrl, mints);
  // console.log('mint', mints)
  // calculate r1
  let percent1 = (apy / 365) * d;
  let apy1 = (apy / 365) * 365;
  let apy30d1 = (apy / 365) * 30;
  let apy1d1 = (apy / 365) * 1;
  // calculate r2
  let percent2 = ((r1s + r2s) * idx - percent1 * r1s) / r2s;
  let apy2 = ((r1s + r2s) * idx1y - apy1 * r1s) / r2s;
  let apy30d2 = ((r1s + r2s) * idx30d - apy30d1 * r1s) / r2s;
  let apy1d2 = ((r1s + r2s) * idx1d - apy1d1 * r1s) / r2s;
  let price = [100 * (1 + percent1), 100 * (1 + percent2)];
  console.log(product.name, "r1 r2 price", price);
  let cap1 = (price[0] * r1s) / 10 ** 6;
  let cap2 = (price[1] * r2s) / 10 ** 6;
  let assetValue1 = [
    maxA,
    percent1,
    apy1,
    apy30d1,
    apy1d1,
    cap1,
    r1s,
    price[0],
    assets,
  ];
  let assetValue2 = [
    maxA,
    percent2,
    apy2,
    apy30d2,
    apy1d2,
    cap2,
    r2s,
    price[1],
    assets,
  ];
  return [assetValue1, assetValue2];
}

export async function getAssetValueSY3(connection, product) {
  return await getAssetValueSY(connection, rpcUrl, product);
}

export function APYCalculate(basePrice, currentPrice, startDate) {
  let period = Math.max(
    1,
    Math.floor(
      (new Date().getTime() - new Date(startDate).getTime()) / 3600000 / 30 / 24
    )
  );
  let apy =
    ((1 + (currentPrice - basePrice) / basePrice / period) ** (1 / period) -
      1) *
    100;
  return apy;
}

export function APYCalculate2(value, pnl) {
  let apy = pnl / (value - pnl);
  return apy;
}

export function PNLCalculate(basePrice, currentPrice, shares) {
  let pnl = (currentPrice - basePrice) * shares;
  return pnl;
}

export async function getAssetValueComposition(connection, product, mint) {
  let totalValue = 0;
  let tokens = product.tokenList;
  let composition = [];
  if (!product.enableWoo) {
    for (let i = 0; i < tokens.length; i++) {
      let res = await connection.getTokenAccountBalance(
        new PublicKey(product.vaultList[i])
      );
      let amount = res.value.uiAmount;
      tokens[i].price = await getTokenPriceLocal(rpcUrl, tokens[i].name);
      let thisV = amount * tokens[i].price;
      totalValue += thisV;
      if (thisV > 0) {
        composition.push({
          token: tokens[i],
          value: thisV,
        });
      }
    }
  } else {
    let { assetList } = product.wooAsset;
    for (let i = 0; i < assetList.length; i++) {
      let asset = assetList[i];
      let { name, amount, price, value } = asset;
      totalValue += value;
      if (value > 0) {
        let token = getTokenInfo(name);
        if (token) {
          composition.push({
            token: {
              name,
              pubkey: token.address,
              img: token.logoURI,
            },
            value,
          });
        } else {
          composition.push({
            token: {
              name,
              pubkey: "",
              img: "",
            },
            value,
          });
        }
      }
    }
  }
  let sapData;
  {
    let res = await getSapData(connection, product.pubkey);
    if (res.code == 1) {
      sapData = res.data;
      totalValue -= sapData.fee;
      totalValue -= sapData.performanceFee;
    }
  }
  let supply = await connection.getTokenSupply(new PublicKey(mint));
  let price = totalValue / supply.value.uiAmount;

  return [price, totalValue, supply.value.uiAmount, composition];
}

export async function getAssetValueSYComposition(
  connection,
  rpcUrl,
  product,
  mints
) {
  let totalValue = 0;
  let tokens = product.tokenList;
  let composition = [];
  if (!product.enableWoo) {
    for (let i = 0; i < tokens.length; i++) {
      let res = await connection.getTokenAccountBalance(
        new PublicKey(product.vaultList[i])
      );
      let amount = res.value.uiAmount;
      tokens[i].price = await getTokenPriceLocal(rpcUrl, tokens[i].name);
      let thisV = amount * tokens[i].price;
      totalValue += thisV;
      if (thisV > 0) {
        composition.push({
          token: tokens[i],
          value: thisV,
        });
      }
    }
  } else {
    let { assetList } = product.wooAsset;
    for (let i = 0; i < assetList.length; i++) {
      let asset = assetList[i];
      let { name, amount, price, value } = asset;
      totalValue += value;
      if (value > 0) {
        let token = getTokenInfo(name);
        if (token) {
          composition.push({
            token: {
              name,
              pubkey: token.address,
              img: token.logoURI,
            },
            value,
          });
        } else {
          composition.push({
            token: {
              name,
              pubkey: "",
              img: "",
            },
            value,
          });
        }
      }
    }
  }
  let sapData;
  {
    let res = await getSyData(connection, product.pubkey);
    if (res.code == 1) {
      sapData = res.data;
      totalValue -= sapData.fee;
      totalValue -= sapData.performanceFee;
    }
  }
  let res = await findSYTokenPrices3(rpcUrl, mints, totalValue, sapData);
  let supplies = res[0];
  let rewards = res[1];
  let values = res[2];
  let price = [
    100 * (1 + rewards[0]).toFixed(3),
    100 * (1 + rewards[1]).toFixed(3),
  ];
  return [price, values[0] + values[1], supplies, composition, values];
}

async function getTokenPriceLocal(url, name) {
  var thisTokenPrice = 1;
  let orcaleObjStr = sessionStorage.getItem("orcale" + name);
  var orcaleObj = {};
  if (orcaleObjStr != "") {
    orcaleObj = JSON.parse(orcaleObjStr);
    if (orcaleObj) {
      let dateNow = new Date();
      if (
        dateNow.getTime() - new Date(orcaleObj.date).getTime() >
        1000 * 60 * 5
      ) {
        orcaleObj = null;
      }
    }
  }
  if (!orcaleObj) {
    // console.log('new oracle', name)
    let ind = oraclePublicKey
      .map((e) => {
        return e.symbol;
      })
      .indexOf(name);
    if (ind == -1) {
      console.error("can not find oracle", name);
      return 0;
    }
    let oracleKey = "";
    let thisTokenExpo = 9;
    if (name != "SYP") {
      oracleKey = oraclePublicKey[ind].price;
      let [thisTokenPricex, thisTokenExpox] = await getTokenPriceOnly(
        url,
        new PublicKey(oracleKey)
      );
      thisTokenPrice = thisTokenPricex;
      thisTokenExpo = thisTokenExpox;
    } else {
      thisTokenPrice = 0.05;
    }
    orcaleObj = { name, price: thisTokenPrice, date: new Date() };
    sessionStorage.setItem("orcale" + name, JSON.stringify(orcaleObj));
  } else {
    // console.log('session storage oracle', name)
    thisTokenPrice = orcaleObj.price;
  }
  return thisTokenPrice;
}

export async function getTokenAccountBalance3(connection, vaultKey) {
  // use account
  let vaultAcc = new PublicKey(vaultKey);
  // get data
  let res = await connection.getParsedAccountInfo(vaultAcc, "confirmed");
  let amount = 0.0;
  if (res.value) {
    if (res.value.data.program == "spl-token") {
      amount = res.value.data.parsed.info.tokenAmount.uiAmount;
    }
  }
  return { value: { uiAmount: amount } };
}
