import { Connection, PublicKey } from "@solana/web3.js";
import {
  parseMappingData,
  parsePriceData,
  parseProductData,
} from "@pythnetwork/client";

export async function getTokenPrice(url, pubkey) {
  let connection = new Connection(url);
  let accountInfo = await connection.getAccountInfo(pubkey);
  const { product, priceAccountKey } = parseProductData(accountInfo.data);
  let dataResult = await connection.getAccountInfo(priceAccountKey);
  if (!dataResult) return [0, 0];
  const { price, confidence, exponent } = parsePriceData(dataResult.data);
  return [price, exponent];
}

export async function getTokenPriceOnly(url, pubkey) {
  let connection = new Connection(url);
  let dataResult = await connection.getAccountInfo(pubkey);
  if (!dataResult) return [0, 0];
  const { price, confidence, exponent } = parsePriceData(dataResult.data);
  return [price, exponent];
}
